$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(document).ready(function (){
	inView();
});

$(window).on('resize scroll', function () {
  inView();
});

function inView() {
  $('section').each(function () {
    // var activePlane = $(this).attr('class');
    if ($(this).isInViewport()) {
      $(this).addClass('in-view');
    } else {
      $(this).removeClass('in-view');
    }
  });
}