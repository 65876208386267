$(document).ready(function (){
  if ("ontouchstart" in window || "ontouch" in window) {
    $('body').addClass('touch');
  }

  // menu toggle
  $('.hamburger').click(function () {
    $(this).toggleClass('is-active');
    $('.main-navigation').toggleClass('menu-open');
  });

  // hide pop over nav
  $('.main-menu a').click(function () {
    $('.main-navigation').removeClass('menu-open');
    $('.hamburger').removeClass('is-active');
  });

  // hero slider
  var $s = $('.services-slides');

  // set us up the bomb
  $s.slick({
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    arrows: true,
    autoplay: false,
    fade: false,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)'
  });


  // featherlight
  // $('.gallery').featherlightGallery({
  //   previousIcon: '<i class="chevron-left" aria-hidden="true"></i>',
  //   nextIcon: '<i class="chevron-right" aria-hidden="true"></i>'
  // });

  $('.gallery').each(function(){
    $(this).find('a').featherlightGallery({
      previousIcon: '<i class="chevron-left" aria-hidden="true"></i>',
      nextIcon: '<i class="chevron-right" aria-hidden="true"></i>'
    });
  });

  // Smooth scroll internal hash links
  // Select all links with hashes
  $('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function (event) {
    // On-page links
    if ( location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top
        }, 1000, function () {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          }
        });
      }
    }
  });
});
